function handleClick(event) {
  const buttonEl = event.currentTarget;
  const navEl = buttonEl.closest('.nav-sidebar');
  const buttonControls = buttonEl.getAttribute('aria-controls').split(' ');
  const submenuEl = navEl.querySelector(`#${buttonControls[0]}`);
  const isExpanded = buttonEl.getAttribute('aria-expanded') === 'true';

  buttonEl.setAttribute('aria-expanded', !isExpanded);
  submenuEl.setAttribute('aria-hidden', isExpanded);
}

function init() {
  const navEls = document.querySelectorAll('.nav-sidebar');

  [...navEls].forEach((navEl) => {
    [...navEl.querySelectorAll('[aria-controls]')].forEach((buttonEl) => {
      buttonEl.addEventListener('click', handleClick);
    });
  });
}


export default {
  init,
};
