import {
  Swiper,
  Navigation,
  Pagination,
  A11y,
  Autoplay,
} from 'swiper/swiper.esm';

  //const btnPrev = document.querySelector('[data-action="swiper-previous"]');
  //const btnNext = document.querySelector('[data-action="swiper-next"]');
// const btnScrollToNextBlock = document.querySelectorAll('[data-action="scroll-to-next"]');

// function registerButtonEvents() {
//   [...btnScrollToNextBlock].forEach((btn) => {
//     btn.addEventListener('click', () => {
//       const closest = btn.closest('.b-carousel');
//       let nextSegment = false;

//       if (closest) { nextSegment = closest.nextElementSibling; }
//       if (nextSegment) { nextSegment.scrollIntoView({ behavior: 'smooth' }); }
//     });
//   });
// }

// function toggleVideos(obj) {
//   const { slides, videos } = obj;
//   const currentIndex = obj.swiper.activeIndex;
//   const video = slides[currentIndex].querySelector('video');

//   [...videos].forEach((v) => { v.pause(); });
//   if (video) { video.play(); }
// }

function updateTextColor(obj) {
  const { slides } = obj;
  const currentIndex = obj.swiper.activeIndex;
  const currentSlideEl = slides[currentIndex];
  const currentTextColor = currentSlideEl.querySelector('.b-carousel-item').dataset.textColor;
  slides[currentIndex].closest('.b-carousel').setAttribute('data-current-text-color', currentTextColor);
}

function updateInfo(obj) {
  const currentIndex = obj.swiper.activeIndex;
  const infos = document.querySelectorAll('.info__container .info');

  infos.forEach((element) => {
    element.classList.add('hidden');
  });

  document.querySelector('.info--' + currentIndex).classList.remove('hidden');
}


function mountCarousels(carouselEls) {
  const sliders = [];

  Swiper.use([Navigation, Pagination, A11y, Autoplay]);

  [...carouselEls].forEach((carouselEl, idx) => {
    const slides = carouselEl.querySelectorAll('.swiper-slide');
    const videos = carouselEl.querySelectorAll('.swiper-slide video');
    //const btnPlayPause = carouselEl.querySelector('.swiper-autoplay-toggle');

    const swiper = new Swiper(carouselEl.querySelector('.swiper-container'), {
      // simulateTouch: false,
      speed: 1000,
      //loop: true,
      /*
      autoplay: {
        delay: 10000,
      },
      */
     /*
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        bulletElement: 'button',
      },
      */
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      a11y: {
        paginationBulletMessage: document.documentElement.lang === 'fi' ? 'Siirry diaan {{index}}' : 'Go to slide {{index}}',
      },
      // autoHeight: true,
      init: () => {
        updateTextColor(sliders[idx]);
      },
    });

    swiper.on('slideChange', () => {
      // updateShortcuts();
      // toggleVideos(sliders[idx]);
      updateTextColor(sliders[idx]);
      updateInfo(sliders[idx]);
    });

    /*
    swiper.on('slideChange', () => {
      // updateShortcuts();
      // toggleVideos(sliders[idx]);
      updateTextColor(sliders[idx]);
      updateInfo(slides[idx]);
    });
    */

    //btnPrev.addEventListener('click', () => swiper.slidePrev());
    //btnNext.addEventListener('click', () => swiper.slideNext());

    /*
    btnPlayPause.addEventListener('click', () => {
      if (swiper.autoplay.running) {
        swiper.autoplay.stop();
        btnPlayPause.setAttribute('aria-pressed', false);
      } else {
        swiper.autoplay.start();
        btnPlayPause.setAttribute('aria-pressed', true);
      }
    });
    */

    sliders[idx] = {
      swiper,
      slides,
      videos,
    };
  });
}

function init() {
  const carouselEls = document.querySelectorAll('.b-carousel');
  if (carouselEls.length) {
    mountCarousels(carouselEls);
    // if (btnScrollToNextBlock) {
    //   registerButtonEvents();
    // }
  }
}

export default {
  init,
};
