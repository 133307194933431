function handleClick(event) {
  const triggerEl = event.currentTarget;
  const targetIds = triggerEl.getAttribute('aria-controls').split(' ');
  const firstTargetEl = document.querySelector(`#${targetIds[0]}`);
  const isExpanded = triggerEl.getAttribute('aria-expanded') === 'true';

  triggerEl.setAttribute('aria-expanded', !isExpanded);
  firstTargetEl.setAttribute('aria-hidden', isExpanded);
}

function init() {
  const langSwitcherDropdownEls = document.querySelectorAll('.lang-switcher--dropdown');

  [...langSwitcherDropdownEls].forEach((el) => {
    el.querySelector('[aria-controls]').addEventListener('click', handleClick);
  });
}

export default {
  init,
};
