import { createFocusTrap } from 'focus-trap';

class SidebarComboPanel {
  constructor({
    // Default options.
    panelEl = '.sidebar-combo',
    panelPanelEl = '.sidebar-panel',
    showEl = '[data-action="sidebar-show"]',
    closeEl = '[data-action="sidebar-close"]',
    //toggleEl = '[data-action="sidebar-toggle"]',
    //toggleEls = '[data-action="sidebar-toggle"]',
    backdropEl = '.sidebar-panel__backdrop',
    breakpoint = 960,
  } = {}) {
    this.panelEl = document.querySelector(panelEl);
    this.panelPanelEl = document.querySelector(panelPanelEl);
    this.showEl = document.querySelector(showEl);
    this.closeEl = document.querySelector(closeEl);
    //this.toggleEl = document.querySelectorAll(toggleEl);
    //this.toggleEls = document.querySelectorAll(toggleEls);
    this.backdropEl = document.querySelector(backdropEl);
    this.breakpointQuery = `(min-width: ${breakpoint}px)`;
    this.mql = window.matchMedia(this.breakpointQuery);

    this.focusTrap = createFocusTrap(this.panelEl, {
      clickOutsideDeactivates: true,
    });
  }

  show() {
    this.panelEl.setAttribute('aria-hidden', false);
    this.panelPanelEl.setAttribute('aria-hidden', false);
    //this.toggleEl.setAttribute('aria-expanded', true);

    this.toggleEls.forEach( (el) => {
      el.setAttribute('aria-expanded', true);
    });

    document.body.classList.add('sidebar--expanded');
  }

  close() {
    this.panelEl.setAttribute('aria-hidden', true);
    this.panelPanelEl.setAttribute('aria-hidden', true);
    //this.toggleEl.setAttribute('aria-expanded', false);

    /*
    this.toggleEls.forEach( (el) => {
      el.setAttribute('aria-expanded', false);
    });
    */

    document.body.classList.remove('sidebar--expanded');
  }

  /*
  toggle() {
    if (this.panelEl.getAttribute('aria-hidden') === 'true') {
      this.show();
    } else {
      this.close();
    }
  }
  */

  handleBreakpoint() {
    if (this.mql.matches) {
      //this.close();
    }
  }

  registerEvents() {
    if (this.showEl) this.showEl.addEventListener('click', () => { this.show(); });
    if (this.closeEl) this.closeEl.addEventListener('click', () => { this.close(); });
    if (this.backdropEl) this.backdropEl.addEventListener('click', () => { this.close(); });
    //if (this.toggleEl) this.toggleEl.addEventListener('click', () => { this.toggle(); });

    if (this.toggleEls) {
      let parent_this = this;
      this.toggleEls.forEach( (el) => {
        el.addEventListener( 'click', () => { parent_this.toggle(); });
      });
    }

    this.mql.addListener(() => { this.handleBreakpoint(); });

    document.addEventListener('keyup', (event) => {
      if (event.keyCode === 27) {
        this.close();
      }
    });

    this.panelEl.addEventListener('transitionend', (event) => {
      if (event.eventPhase === Event.AT_TARGET && event.propertyName === 'visibility') {
        if (event.target.getAttribute('aria-hidden') === 'true') {
          this.focusTrap.deactivate();
        } else {
          this.focusTrap.activate();
        }
      }
    });

    this.panelEl.addEventListener('mouseenter', (event) => {
      console.log('enter!');
      event.target.setAttribute('aria-hidden', false);

      /*
      if (event.target.getAttribute('aria-expanded') === 'true') {
        event.target.setAttribute('aria-expanded', false);
      }
      */
    });

    this.panelEl.addEventListener('mouseleave', (event) => {
      console.log('leave!');
      event.target.setAttribute('aria-hidden', true);

    });


  }


  mount() {
    if (this.panelEl) {
      this.registerEvents();
    }
  }
}

function init() {
  // TODO: This should be consistent with the breakpoint in CSS.
  new SidebarComboPanel({
    breakpoint: 960,
  }).mount();
}

export default {
  init,
};
